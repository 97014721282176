import React, { useEffect, useState } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import { useNavigate } from 'react-router-dom';
import LeftNavigationButton from './LeftNavigationButton';
import {
  BarChart,
  DoorClosed,
  FileText,
  Gear,
  GearWideConnected,
  Globe,
  Incognito,
  People,
  TelephoneOutbound,
  Upload,
} from 'react-bootstrap-icons';
import DRE_LOGO_GOOGLE from '../../assets/dre-logo-google.png';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { BiCopy, BiHome } from 'react-icons/bi';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { getDonorsPurchasedCount } from 'api/donorlist.service';
import { useSearchSlice } from 'app/pages/SearchPage/slice';
import {
  selectHasPurchases,
  selectHasIngested,
} from 'app/pages/SearchPage/slice/selectors';
import { useMediaQuery } from '@mui/material';

export const LeftNavigation = () => {
  const userLogged = useSelector(getAuthUser);

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  const {
    actions: { makeLogout },
  } = useAuthSliceSlice();
  const {
    actions: { setHasPurchasesIngested },
  } = useSearchSlice();
  const hasPurchases = useSelector(selectHasPurchases);
  const hasIngested = useSelector(selectHasIngested);
  const navigate = useNavigate();
  const [isAdmin] = useState(userLogged?.role === 'admin');
  const isMobile = useMediaQuery(`( max-width: 1224px )`);

  const handleLogout = () => {
    removeCookie('user');
    dispatch(makeLogout());
  };

  useEffect(() => {
    if (userLogged?.organizationId) {
      getDonorsPurchasedCount(userLogged!.email).then(result => {
        dispatch(
          setHasPurchasesIngested({
            hasPurchases:
              parseInt((result?.data as any)?.purchasedCount, 10) >= 1,
            hasIngested:
              parseInt((result?.data as any)?.ingestedCount, 10) >= 1,
          }),
        );
      });
    }
  }, [dispatch, setHasPurchasesIngested, userLogged]);

  return isMobile ? (
    <Navbar
      expand="lg"
      bg="dark"
      data-bs-theme="dark"
      className="leftNavigation"
    >
      <Container>
        <Navbar.Brand href="#home">
          <Image fluid className="me-2" src={DRE_LOGO_GOOGLE} /> beta
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {!isAdmin && (
              <LeftNavigationButton isMobile onClick={() => navigate('/')}>
                <BiHome className="me-2" /> Home
              </LeftNavigationButton>
            )}

            {false && !isAdmin && (
              <LeftNavigationButton isMobile onClick={() => navigate('/match')}>
                <BiCopy className="me-2" /> Match
              </LeftNavigationButton>
            )}

            {false && !isAdmin && userLogged?.canAccessMOADBQuery && (
              <LeftNavigationButton
                isMobile
                onClick={() => navigate('/p2p-message-queue')}
              >
                <TelephoneOutbound className="me-2" /> P2P Message Queue
              </LeftNavigationButton>
            )}

            {!isAdmin && userLogged?.canAccessMOADBQuery && (
              <LeftNavigationButton
                isMobile
                onClick={() => navigate('/process-monitor')}
              >
                <GearWideConnected className="me-2" /> Process Monitor
              </LeftNavigationButton>
            )}

            {!isAdmin && userLogged?.canAccessMOADBQuery && (
              <LeftNavigationButton
                isMobile
                onClick={() => navigate('/prospect-importer')}
              >
                <GearWideConnected className="me-2" /> Prospect Importer
              </LeftNavigationButton>
            )}

            {!isAdmin && (hasPurchases || hasIngested) && (
              <LeftNavigationButton
                isMobile
                onClick={() => navigate('/prospect-tracker')}
              >
                <People className="me-2" /> Prospect Tracker
              </LeftNavigationButton>
            )}

            {!isAdmin && userLogged?.canAccessMOADBQuery && (
              <LeftNavigationButton
                isMobile
                onClick={() => navigate('/reports')}
              >
                <BarChart className="me-2" /> Reports
              </LeftNavigationButton>
            )}

            {!isAdmin && userLogged?.canAccessMOADBQuery && (
              <LeftNavigationButton
                isMobile
                onClick={() => navigate('/scraper-script')}
              >
                <Globe className="me-2" /> Scraper
              </LeftNavigationButton>
            )}

            {!isAdmin && (
              <LeftNavigationButton
                isMobile
                onClick={() => navigate('/settings')}
              >
                <Gear className="me-2" /> Manage Subscriptions
              </LeftNavigationButton>
            )}

            <LeftNavigationButton isMobile onClick={handleLogout}>
              <DoorClosed className="me-2" /> Logout
            </LeftNavigationButton>

            <LeftNavigationButton
              isMobile
              onClick={() =>
                window.open('https://www.dredata.ai/terms', '_blank')
              }
            >
              <FileText className="me-2" /> Terms
            </LeftNavigationButton>

            <LeftNavigationButton
              isMobile
              onClick={() =>
                window.open('https://www.dredata.ai/privacy', '_blank')
              }
            >
              <Incognito className="me-2" /> Privacy
            </LeftNavigationButton>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  ) : (
    <div className="leftNavigation">
      <LeftNavigationButton onClick={() => navigate('/')}>
        <Image fluid className="me-2" src={DRE_LOGO_GOOGLE} /> beta
      </LeftNavigationButton>

      {!isAdmin && (
        <LeftNavigationButton onClick={() => navigate('/')}>
          <BiHome className="me-2" /> Home
        </LeftNavigationButton>
      )}

      {false && !isAdmin && (
        <LeftNavigationButton onClick={() => navigate('/match')}>
          <BiCopy className="me-2" /> Match
        </LeftNavigationButton>
      )}

      {false && !isAdmin && userLogged?.canAccessMOADBQuery && (
        <LeftNavigationButton onClick={() => navigate('/p2p-message-queue')}>
          <TelephoneOutbound className="me-2" /> P2P Message Queue
        </LeftNavigationButton>
      )}

      {!isAdmin && userLogged?.canAccessMOADBQuery && (
        <LeftNavigationButton onClick={() => navigate('/process-monitor')}>
          <GearWideConnected className="me-2" /> Process Monitor
        </LeftNavigationButton>
      )}

      {!isAdmin && userLogged?.canAccessMOADBQuery && (
        <LeftNavigationButton onClick={() => navigate('/prospect-importer')}>
          <Upload className="me-2" /> Prospect Importer
        </LeftNavigationButton>
      )}

      {!isAdmin && (hasPurchases || hasIngested) && (
        <LeftNavigationButton onClick={() => navigate('/prospect-tracker')}>
          <People className="me-2" /> Prospect Tracker
        </LeftNavigationButton>
      )}

      {!isAdmin && userLogged?.canAccessMOADBQuery && (
        <LeftNavigationButton onClick={() => navigate('/reports')}>
          <BarChart className="me-2" /> Reports
        </LeftNavigationButton>
      )}

      {!isAdmin && userLogged?.canAccessMOADBQuery && (
        <LeftNavigationButton onClick={() => navigate('/scraper-script')}>
          <Globe className="me-2" /> Scraper
        </LeftNavigationButton>
      )}

      {!isAdmin && (
        <LeftNavigationButton onClick={() => navigate('/settings')}>
          <Gear className="me-2" /> Manage Subscriptions
        </LeftNavigationButton>
      )}

      <LeftNavigationButton onClick={handleLogout}>
        <DoorClosed className="me-2" /> Logout
      </LeftNavigationButton>

      <div className="footer">
        <LeftNavigationButton
          onClick={() => window.open('https://www.dredata.ai/terms', '_blank')}
        >
          <FileText className="me-2" /> Terms of Use
        </LeftNavigationButton>

        <LeftNavigationButton
          onClick={() =>
            window.open('https://www.dredata.ai/privacy', '_blank')
          }
        >
          <Incognito className="me-2" /> Privacy Policy
        </LeftNavigationButton>
      </div>
    </div>
  );
};

export default LeftNavigation;
