import React from 'react';
import { Button, Nav } from 'react-bootstrap';

export const LeftNavigationButton = ({
  children,
  isMobile = false,
  onClick = () => {},
}) => {
  return isMobile ? (
    <Nav.Link onClick={onClick}>{children}</Nav.Link>
  ) : (
    <Button className="leftNavigationButton w-100" onClick={onClick}>
      {children}
    </Button>
  );
};

export default LeftNavigationButton;
