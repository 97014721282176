export const orgCreditsUsed = organization => {
  return (
    (organization.prospects_purchased ?? [])
      .map(purchase => purchase.credits)
      .reduce((p, c) => p + c, 0) +
    (organization.searchHistory ?? []).length +
    (organization.prospects_matched ?? [])
      .map(row => row.matchCount)
      .reduce((p, c) => p + c, 0)
  );
};
