import React, { useRef, useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import ToolTip from '../ToolTip';
import { markAsPurchased, queryMOADB } from 'api/moadb.service';
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ArrowDownSquare,
  ArrowDownSquareFill,
  ArrowUpSquare,
  BrowserChrome,
  ChatDots,
  CheckSquare,
  FileText,
  GeoAlt,
  Incognito,
  InfoSquare,
  List,
  PeopleFill,
  QuestionCircle,
  Square,
  Stars,
  Upload,
} from 'react-bootstrap-icons';
import { MOADBDropdownRegions } from '../MOADBDropdownRegions';
import { MOADBDropdownStates } from '../MOADBDropdownStates';
import { MOADBDropdownCustomers } from '../MOADBDropdownCustomers';
import {
  Accordion,
  Alert,
  Card,
  Form,
  ListGroup,
  Table,
} from 'react-bootstrap';
import LoadingOverlay from '../LoadingOverlay';
import { Toggle } from '../Toggle';
import GeneralConfirmationModal from '../GeneralConfirmationModal/GeneralConfirmationModal';
import generateCSV from 'utils/generateCSV';
import { MultiSelect } from '../MultiSelect';
import { InputSelect } from '../InputSelect';
import { AddressLookup } from '../AddressLookup';
import { EventBasedTable } from '../EventBasedTable';
import './styles.css';
import { InsetLabel } from '../InsetLabel';
import NewModal from './NewModal';
import OpenModal from './OpenModal';
import SaveModal from './SaveModal';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { toast } from 'react-toastify';
import {
  getFacetedSearches,
  insertFacetedSearchRecords,
} from 'api/faceted.search.service';
import FileDetailsModal from './FileDetailsModal';
import { MOADBMap } from '../MOADBMap';
import { useHistory } from 'custom-hooks/useHistory';
import { toFixed } from 'utils/toFixed';
import DataProfileModal from './DataProfileModal';
import { historyMask, newFacets } from './utils';
import { MOADBDropdownCounties } from '../MOADBDropdownCounties';
import UpdloadAndMarkAsPurchsedModal from './UpdloadAndMarkAsPurchsedModal';
import { sendSlackMessage } from 'api/slack.service';
import GeneralModal from '../GeneralModal/GeneralModal';
import FeedbackModal from './FeedbackModal';
//import Catalog from '../Stripe';
import { getOneOrganization } from 'api/organization.service';
import { orgCredits } from 'utils/orgCredits';
import CreditsModal from './CreditsModal';
import { useConfirmation } from 'custom-hooks/useConfirmation';
import { orgStatus } from 'utils/orgStatus';
import GuideSection from './GuideSection';
import { orgHasSideBrowserkickAccess } from 'utils/orgHasSideBrowserkickAccess';
import { orgHasBasicAccess } from 'utils/orgHasBasicAccess';
import { useSearchParams } from 'react-router-dom';
import Preview from '../../assets/preview.png';
import PreviewLive from '../../assets/preview_live.png';
import QNA from '../QNA';
import { updateAnalytics } from 'utils/updateAnalytics';
import { orgCreditsUsed } from 'utils/orgCreditsUsed';

export const MOADBFacetedSearch = ({ setUseQuery }) => {
  const [searchParams] = useSearchParams();
  const userLogged = useSelector(getAuthUser);
  const [organization, setOrganization] = useState<any>(null);
  const { confirm, ConfirmationModal } = useConfirmation();
  const [credits, setCredits] = useState<any>(0);
  const [creditsUsed, setCreditsUsed] = useState<any>(0);
  const [creditsDelta, setCreditsDelta] = useState<any>(0);
  const [error, setError] = useState<any>(null);
  const [includePolitical, setIncludePolitical] = useState<boolean>(false);
  const facets = useHistory(
    newFacets({
      customerId: !!userLogged ? userLogged.moadbCustomerId : null,
      hasPhone: true,
      ideology: [],
    }),
    historyMask(),
  );
  const [facetError, setFacetError] = useState<any>(null);
  const [latestSearchTime, setLatestSearchTime] = useState(Date.now());
  const [markedAsPurchased, setMarkedAsPurchased] = useState<boolean>(false);
  const [facetedSearches, setFacetedSearches] = useState<any>([]);
  const [results, setResults] = useState<any>(null);
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(false);
  const resultsAreLoadingRef = useRef<boolean>(false);
  const [counted, setCounted] = useState(false);
  const [markSingleProspect, setMarkSingleProspect] = useState<any>(null);
  const [searchPurchased, setSearchPurchased] = useState(
    !!searchParams.get('searchLicensed'),
  );
  const [searchingMyProspects, setSearchingMyProspects] = useState(false);
  const [savingAs, setSavingAs] = useState<boolean>(false);
  const [slackConfirmationMessage, setSlackConfirmationMessage] =
    useState<any>(null);
  const [slackConfirmationTitle, setSlackConfirmationTitle] =
    useState<any>(null);
  const [showContactInfo, setShowContactInfo] = useState<boolean>(
    (!!searchPurchased ||
      ((userLogged?.permissions ?? []) as any).includes(
        'facetedSearchCanViewContactInfo',
      )) ??
      false,
  );
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showFileDetailsModal, setShowFileDetailsModal] =
    useState<boolean>(false);
  const [showDataProfileModal, setShowDataProfileModal] = useState(false);
  const [showMarkModal, setShowMarkModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showOpenModel, setShowOpenModal] = useState<boolean>(false);
  const [showPoliticalModal, setShowPoliticalModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [
    showUploadAndMarkAsPurchsedModal,
    setShowUploadAndMarkAsPurchsedModal,
  ] = useState(false);
  const [revealedColumns, setRevealedColumns] = useState(
    showContactInfo
      ? [
          'address_1',
          'address_2',
          'address_latitude',
          'address_longitude',
          'city',
          'count_1',
          'dma_market_region',
          'postal_code',
          'state',
        ]
      : ['city', 'count_1', 'dma_market_region', 'postal_code', 'state'],
  );

  /*const appendPurchaseHistory = payment => {
    const newOrg = {
      ...organization,
      paymentHistory: [...organization.paymentHistory, payment],
    };
    setOrganization(newOrg);
    setCredits(orgCredits(newOrg));
  };*/

  const clearConfirmationState = () => {
    setSlackConfirmationMessage(null);
    setSlackConfirmationTitle(null);
  };

  const loadFacetedSearches = useCallback(() => {
    getFacetedSearches({
      email: userLogged!.email,
    }).then(response => {
      setFacetedSearches(response.data.facetedSearches);
    });
  }, [userLogged]);

  const onColumnFilter = useCallback(
    column => {
      return (
        ![
          'email_deliverability_code_list',
          'email_list',
          'email_match_type_list',
          'estimated_rental_value',
          'home_value_estimated_at',
          'household_income_net',
          'moadb_voter_id_legacy',
        ].includes(column) &&
        (includePolitical ||
          ![
            'general_election_turnout',
            'jurisdiction_name',
            'modeled_party',
            'modeled_spectrum_brackets',
            'modeled_spectrum_score',
            'ideology',
            'state_house_district',
            'state_senate_district',
            'us_house_district',
            'voter_status',
          ].includes(column))
      );
    },
    [includePolitical],
  );

  const onColumnHeaderChanged = useCallback(
    column => {
      const index = revealedColumns.indexOf(column);

      if (index >= 0) revealedColumns.splice(index, 1);
      else revealedColumns.push(column);

      setRevealedColumns([...revealedColumns]);
    },
    [revealedColumns],
  );

  const onColumnHeader = useCallback(
    (column, includeChecks = true) => {
      let columnHeader = column
        .replace(/[^a-z0-9_]/gi, '')
        .replace(/_/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();

      if (column === 'cell_phone_alesco') columnHeader = 'cell phone a';
      else if (column === 'cell_phone_hub224') columnHeader = 'cell phone b';
      else if (column === 'count_1') columnHeader = 'count';
      else if (column === 'landline_phone_alesco')
        columnHeader = 'landline_phone_a';
      else if (column === 'landline_phone__hub224')
        columnHeader = 'landline phone b';
      else if (column === 'moadb_voter_id') columnHeader = 'id';
      else if (column === 'other_phone_alesco') columnHeader = 'other phone a';

      return !includeChecks ||
        (!showContactInfo &&
          [
            'address_1',
            'address_2',
            'address_latitude',
            'address_longitude',
            'cell_phone_alesco',
            'cell_phone_hub224',
            'email_list',
            'landline_phone_alesco',
            'landline_phone__hub224',
            'other_phone_alesco',
          ].includes(column)) ? (
        columnHeader
      ) : (
        <Form.Check
          checked={revealedColumns.includes(column)}
          label={columnHeader}
          onChange={() => onColumnHeaderChanged(column)}
        />
      );
    },
    [onColumnHeaderChanged, revealedColumns, showContactInfo],
  );

  const onGenerateCSV = async () => {
    setShowDownloadModal(false);
    await onSearch(false, true);
  };

  const onMarkPurchased = async () => {
    setShowMarkModal(false);
    setResultsAreLoading(true);
    const dt_ids = results.map(row => row['id']);

    updateAnalytics('facetedSearchBatchLicense', {
      user: userLogged?.email,
      dt_ids: dt_ids,
    });

    markAsPurchased({
      customer_id: facets.current?.Facets?.customerId,
      dt_ids,
      organizationId: userLogged?.organizationId,
    }).then(response => {
      setResultsAreLoading(false);
      if (!!response?.data?.moadb?.purchase_record_id) {
        toast.info(`Prospects marked as licensed successfully`);
        setOrganization(response?.data?.organization);
        setCredits(orgCredits(response?.data?.organization));
        setCreditsUsed(orgCreditsUsed(response?.data?.organization));
        setMarkedAsPurchased(true);
        setResults(null);
      }
    });
  };

  const onMarkSinglePurchased = async () => {
    setMarkSingleProspect(null);
    setResultsAreLoading(true);
    const dt_ids = [markSingleProspect['id']];

    updateAnalytics('facetedSearchSingleLicense', {
      user: userLogged?.email,
      dt_ids: dt_ids,
    });

    markAsPurchased({
      customer_id: facets.current?.Facets?.customerId,
      dt_ids,
      organizationId: userLogged?.organizationId,
    }).then(response => {
      setResultsAreLoading(false);
      if (!!response?.data?.moadb?.purchase_record_id) {
        toast.info(`Prospect marked as licensed successfully`);
        const newResults = results.filter(
          row => row.id !== markSingleProspect.id,
        );
        setOrganization(response?.data?.organization);
        setCredits(orgCredits(response?.data?.organization));
        setCreditsUsed(orgCreditsUsed(response?.data?.organization));
        setResults(newResults);
      }
    });
  };

  const onRowColumn = (row, column) => {
    if (revealedColumns.includes(column)) return row[column];
    else return '*'.repeat((row?.[column] ?? '').length);
  };

  const onSearch = useCallback(
    async (
      markAsPurchased = false,
      download = false,
      customFacets: any = null,
      count = false,
    ) => {
      setCounted(count);
      if (!!count) setShowMap(false);
      if (!searchPurchased) setShowMap(true);

      const actualFacets = customFacets ?? facets.current;

      if (!actualFacets?.Facets?.customerId) setFacetError('Customer required');
      else {
        updateAnalytics('facetedSearchQuery', {
          user: userLogged?.email,
          ...actualFacets?.Facets,
        });

        setResultsAreLoading(true);
        setFacetError(null);
        queryMOADB(
          {
            count,
            customerId: actualFacets?.Facets?.customerId,
            includePolitical,
            limit: actualFacets?.Facets?.limit,
            markAsPurchased,
            organizationId: organization.Organization_ID,
            order: actualFacets?.Facets?.order,
            purchased: searchPurchased,
            reveal: showContactInfo,
            sort: actualFacets?.Facets?.sort,
            time: Date.now(),
            sqlElements: [
              !!actualFacets?.Facets?.county?.[0]
                ? {
                    type: 'in',
                    field: 'JURISDICTION_NAME',
                    value: actualFacets?.Facets?.county,
                  }
                : null,
              actualFacets?.Facets?.hasEmail
                ? { type: 'is not null', field: 'PRIMARY_EMAIL', value: null }
                : null,
              actualFacets?.Facets?.hasPhone
                ? {
                    type: 'or',
                    children: [
                      {
                        type: 'is not null',
                        field: 'CELL_PHONE_HUB224',
                        value: null,
                      },
                      {
                        type: 'is not null',
                        field: 'LANDLINE_PHONE__HUB224',
                        value: null,
                      },
                    ],
                  }
                : null,
              !!actualFacets?.Facets?.interests?.[0]
                ? {
                    type: 'or',
                    children: actualFacets?.Facets?.interests
                      .map(interest => ({
                        type: '!=',
                        field: interest,
                        value: '',
                      }))
                      .reduce((p, c) => [...p, c], []),
                  }
                : null,
              actualFacets?.Facets?.is4YrGrad
                ? { type: '=', field: 'IS_4YR_GRADUATE', value: 'Yes' }
                : null,
              !!actualFacets?.Facets?.latLon
                ? [
                    {
                      type: 'is not null',
                      field: 'ADDRESS_LATITUDE',
                      value: null,
                    },
                    {
                      type: '!=',
                      field: 'ADDRESS_LATITUDE',
                      value: '',
                    },
                    {
                      type: 'is not null',
                      field: 'ADDRESS_LONGITUDE',
                      value: null,
                    },
                    {
                      type: '!=',
                      field: 'ADDRESS_LONGITUDE',
                      value: '',
                    },
                    {
                      type: 'distance',
                      value: {
                        ...{
                          lat: actualFacets?.Facets?.latLon.lat,
                          long: actualFacets?.Facets?.latLon.lon,
                        },
                        distance: actualFacets?.Facets?.distance,
                      },
                    },
                  ]
                : null,
              !!actualFacets?.Facets?.minDonationCapacity
                ? {
                    type: '>=',
                    field: 'DONATION_CAPACITY',
                    value: actualFacets?.Facets?.minDonationCapacity,
                  }
                : null,
              !!actualFacets?.Facets?.minEstimatedHomeValue
                ? {
                    type: '>=',
                    field: 'ESTIMATED_HOME_VALUE',
                    value: actualFacets?.Facets?.minEstimatedHomeValue,
                  }
                : null,
              !!actualFacets?.Facets?.minEstimatedIncome
                ? {
                    type: '>=',
                    field: 'HOUSEHOLD_INCOME_TOTAL',
                    value: actualFacets?.Facets?.minEstimatedIncome,
                  }
                : null,
              !!actualFacets?.Facets?.name
                ? {
                    type: 'or',
                    children: [
                      {
                        type: 'like',
                        field: 'first_name',
                        value: actualFacets?.Facets?.name,
                      },
                      {
                        type: 'like',
                        field: 'last_name',
                        value: actualFacets?.Facets?.name,
                      },
                      {
                        type: 'like',
                        field: ['first_name', 'last_name'],
                        value: actualFacets?.Facets?.name,
                      },
                      {
                        type: 'like',
                        field: ['last_name', 'first_name'],
                        value: actualFacets?.Facets?.name,
                      },
                    ],
                  }
                : null,

              !!actualFacets?.Facets?.ideology?.[0]
                ? {
                    type: 'in',
                    field: 'IDEOLOGY',
                    value: actualFacets?.Facets?.ideology,
                  }
                : null,
              !!actualFacets?.Facets?.religion?.[0]
                ? {
                    type: 'in',
                    field: 'MODELED_RELIGION',
                    value: actualFacets?.Facets?.religion,
                  }
                : null,
              !!actualFacets?.Facets?.state?.[0]
                ? {
                    type: 'in',
                    field: 'STATE',
                    value: actualFacets?.Facets?.state,
                  }
                : null,
            ]
              .filter(a => a)
              .reduce(
                (p: any, c: any) => [...p, ...(Array.isArray(c) ? c : [c])],
                [],
              ),
          },
          response => {
            const error = response?.[0]?.__error;

            if (!!error)
              setError(`MOADB Returned Error: ${decodeURIComponent(error)}`);
            else {
              setError(null);

              if (download)
                generateCSV({ data: response, onColumnFilter, onColumnHeader });

              if (markAsPurchased) {
                setMarkedAsPurchased(true);
                setResults(null);
              } else {
                setMarkedAsPurchased(false);
                setResults(response);
              }
            }

            setResultsAreLoading(false);
            setLatestSearchTime(Date.now());
            resultsAreLoadingRef.current = false;
          },
        );
      }
    },
    [
      facets,
      includePolitical,
      onColumnFilter,
      onColumnHeader,
      organization,
      searchPurchased,
      showContactInfo,
      userLogged?.email,
    ],
  );

  const onSearchMyProspects = uShowMap => {
    updateAnalytics(
      uShowMap ? 'facetedSearchMapProspects' : 'facetedSearchListView',
      {
        user: userLogged?.email,
      },
    );

    if (!results || !searchPurchased) {
      updateFacets(
        newFacets({
          customerId: !!userLogged ? userLogged.moadbCustomerId : null,
          searchPurchased: true,
        }),
      );

      setSearchPurchased(true);
      setSearchingMyProspects(true);
    }

    setShowMap(uShowMap);
  };

  const openFacetedSearch = newFacets => {
    if (newFacets.searchPurchased !== undefined) {
      setSearchPurchased(!!newFacets.searchPurchased);
      delete newFacets.searchPurchased;
    } else setSearchPurchased(false);

    facets.update(newFacets);
    setShowNewModal(false);
    setShowOpenModal(false);
  };

  const saveFacetedSearch = (newFacets: any = null) => {
    const saveFacets = newFacets ?? facets.current;

    if (savingAs && !!saveFacets?._id) delete saveFacets._id;

    insertFacetedSearchRecords({
      email: userLogged!.email,
      facets: saveFacets,
    }).then(response => {
      facets.update(response.data.facets);
      setShowSaveModal(false);
      setSavingAs(false);
      loadFacetedSearches();
      toast.info(`"${saveFacets?.Name}" saved successfully`);
    });
  };

  const saveQueryAs = () => {
    setSavingAs(true);
    setShowSaveModal(true);
  };

  const saveQueryOrSaveQueryAs = () => {
    if (!!facets?.current?._id) saveFacetedSearch();
    else setShowSaveModal(true);
  };

  const requestSendingSlackMessage = (
    event,
    message,
    confirmationTitle,
    confirmationMessage,
  ) => {
    updateAnalytics(event, {
      user: userLogged?.email,
    });

    sendSlackMessage(
      userLogged?.email,
      [
        message,
        `email: ${userLogged?.email}`,
        `DRE User ID: ${userLogged?._id}`,
        `DRE Org ID: ${userLogged?.organizationId}`,
        `MOADB Customer ID: ${userLogged?.moadbCustomerId}`,
      ].join('\r\n'),
    ).then(response => {
      setSlackConfirmationTitle(confirmationTitle);
      setSlackConfirmationMessage(confirmationMessage);
    });
  };

  const updateFacets = newFacets => {
    facets.update({
      ...newFacets,
    });
  };

  const updateIncludePolitical = newValue => {
    setIncludePolitical(newValue);
    setResults(null);
  };

  const updateSearchPurchased = newValue => {
    setSearchPurchased(newValue);
    setResults(null);
  };

  useEffect(() => {
    loadFacetedSearches();
  }, [loadFacetedSearches, userLogged]);

  useEffect(() => {
    const newShowContactInfo =
      (!!searchPurchased ||
        ((userLogged?.permissions ?? []) as any).includes(
          'facetedSearchCanViewContactInfo',
        )) ??
      false;

    setShowContactInfo(newShowContactInfo);

    setRevealedColumns(
      newShowContactInfo
        ? [
            'address_1',
            'address_2',
            'address_latitude',
            'address_longitude',
            'city',
            'count_1',
            'dma_market_region',
            'postal_code',
            'state',
          ]
        : ['city', 'count_1', 'dma_market_region', 'postal_code', 'state'],
    );
  }, [searchPurchased, showContactInfo, userLogged?.permissions]);

  useEffect(() => {
    if (!!userLogged?.organizationId)
      getOneOrganization({ Organization_ID: userLogged?.organizationId }).then(
        response => {
          setOrganization(response);
          setCredits(orgCredits(response));
          setCreditsUsed(orgCreditsUsed(response));
        },
      );
  }, [userLogged?.organizationId, latestSearchTime]);

  useEffect(() => {
    if (searchingMyProspects) {
      setSearchingMyProspects(false);
      onSearch(false, false, null, false);
    }
  }, [onSearch, searchingMyProspects]);

  useEffect(() => {
    updateAnalytics('facetedSearchLoaded', {
      user: userLogged?.email,
    });
  }, [userLogged?.email]);

  if (!organization) return null;

  /*if (!isOrganizationSubscribed(organization) && !organization.canBypassPaywall)
    return (
      <Catalog organization={organization} onPurchase={appendPurchaseHistory} />
    );*/

  return (
    <>
      <FileDetailsModal
        onHide={() => setShowFileDetailsModal(false)}
        facets={facets}
        updateFacets={updateFacets}
        show={showFileDetailsModal}
      />

      {ConfirmationModal}

      {!!results && (
        <GeneralConfirmationModal
          message={
            !!userLogged?.moadbCustomerId ? (
              <>
                {results.length.toLocaleString()} records will be licensed for{' '}
                {results.length.toLocaleString()} credits. You currently have{' '}
                {credits.toLocaleString()} credits.
                <strong>This action cannot be undone.</strong>
              </>
            ) : (
              <>
                {results.length.toLocaleString()} records will be marked as
                licensed for this customer for {results.length.toLocaleString()}{' '}
                credits. <strong>This action cannot be undone.</strong>
              </>
            )
          }
          onHide={() => setShowMarkModal(false)}
          onOK={onMarkPurchased}
          show={showMarkModal}
          title={
            !!userLogged?.moadbCustomerId ? 'License All' : 'Mark As Licensed'
          }
        />
      )}

      <GeneralConfirmationModal
        message={
          !!userLogged?.moadbCustomerId ? (
            <>
              Prospect will be licensed for 1 credit. You currently have{' '}
              {credits.toLocaleString()} credits.
              <strong>This action cannot be undone.</strong>
            </>
          ) : (
            <>
              Prospect will be marked as licensed for this customer.{' '}
              <strong>This action cannot be undone.</strong>
            </>
          )
        }
        onHide={() => setMarkSingleProspect(null)}
        onOK={onMarkSinglePurchased}
        show={!!markSingleProspect}
        title={!!userLogged?.moadbCustomerId ? 'License' : 'Mark As Licensed'}
      />

      <GeneralConfirmationModal
        isError
        message={
          <div className="fs-5 text-danger">
            This export will include political columns. Be certain to exclude
            political columns when exporting for commercial clients. Sending
            commercial clients political columns may have{' '}
            <u>legal ramifications</u>.
          </div>
        }
        onHide={() => setShowDownloadModal(false)}
        onOK={onGenerateCSV}
        show={showDownloadModal}
        title="Generate CSV"
      />

      <GeneralConfirmationModal
        isError
        message={
          <div className={!includePolitical ? 'fs-5 text-danger' : ''}>
            {`Are you certain you want to ${
              includePolitical ? 'exclude' : 'include'
            } politcal columns?`}
            {!includePolitical && (
              <>
                &nbsp; Be certain to exclude political columns when exporting
                for commercial clients. Sending commercial clients political
                columns may have <u>legal ramifications</u>.
              </>
            )}
          </div>
        }
        onHide={() => setShowPoliticalModal(false)}
        onOK={() => {
          updateIncludePolitical(!includePolitical);
          setShowPoliticalModal(false);
        }}
        show={showPoliticalModal}
        title="Include Political Columns"
      />

      <GeneralModal
        message={slackConfirmationMessage}
        onHide={clearConfirmationState}
        show={!!slackConfirmationMessage}
        title={slackConfirmationTitle}
      />

      <DataProfileModal
        customerId={facets.current.Facets.customerId}
        onComplete={newFacets => {
          setShowDataProfileModal(false);
          facets.update(newFacets);
        }}
        onHide={() => setShowDataProfileModal(false)}
        show={showDataProfileModal}
      />

      <NewModal
        customerId={!!userLogged ? userLogged.moadbCustomerId : null}
        facetedSearches={facetedSearches}
        onHide={() => setShowNewModal(false)}
        onOpen={openFacetedSearch}
        show={showNewModal}
      />

      <OpenModal
        facetedSearches={facetedSearches}
        onHide={() => setShowOpenModal(false)}
        onOpen={openFacetedSearch}
        show={showOpenModel}
      />

      <SaveModal
        onHide={() => setShowSaveModal(false)}
        onSave={saveFacetedSearch}
        facets={{ ...facets.current, searchPurchased }}
        updateFacets={updateFacets}
        show={showSaveModal}
      />

      <UpdloadAndMarkAsPurchsedModal
        onHide={() => setShowUploadAndMarkAsPurchsedModal(false)}
        show={showUploadAndMarkAsPurchsedModal}
      />

      <FeedbackModal
        show={showFeedbackModal}
        onHide={() => setShowFeedbackModal(false)}
        onSend={feedback => {
          setShowFeedbackModal(false);
          requestSendingSlackMessage(
            'facetedSearchFeedback',
            ['Real-time feedback.', feedback].join('\r\n'),
            'Real-time feeback.',
            'Real-time feedback sent',
          );
        }}
      />

      <CreditsModal
        credits={credits}
        delta={creditsDelta}
        onHide={() => setCreditsDelta(0)}
        show={creditsDelta > 0}
      />

      <Container fluid className="mt-3">
        <LoadingOverlay show={resultsAreLoading} />

        <Row className="mt-3 row-eq-height">
          <Col md={3} className="mb-3 text-center">
            <GuideSection title="Welcome">
              You have the <i>{orgStatus(organization)}</i> plan.
              {!orgHasSideBrowserkickAccess(organization) && (
                <Button className="w-100 my-2" href="/settings">
                  Upgrade to Sidekick Plus
                </Button>
              )}
              {!orgHasBasicAccess(organization) && (
                <Button className="w-100 my-2" href="/settings">
                  Upgrade to Professional Client Finder
                </Button>
              )}
              {orgHasBasicAccess(organization) && (
                <Button
                  size="sm"
                  variant="primary"
                  className="p-1 w-100"
                  href="/settings"
                >
                  Purchase Additional Credits
                </Button>
              )}
            </GuideSection>
          </Col>

          <Col md={5} className="mb-3">
            <GuideSection title="How to Use">
              <ListGroup variant="flush" as="ol" numbered>
                <ListGroup.Item as="li">
                  <a
                    className="text-decoration-none"
                    target="_blank"
                    href="https://chromewebstore.google.com/detail/dre/hnkemefafbgmbmffecmlgpmjoifclcei"
                    rel="noreferrer"
                  >
                    <b>Activate</b> your Sidekick extension to power A.I.
                    predictions for any name you see online
                  </a>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <b>Hover</b> over a highlighted name as you browse online to
                  see the Profile on that person
                </ListGroup.Item>
                {!!orgHasSideBrowserkickAccess(organization) && (
                  <ListGroup.Item as="li">
                    <b>License</b> the Profiles you find interesting to make
                    them a Prospect and see their details and contact
                    information.
                  </ListGroup.Item>
                )}
                {!!orgHasBasicAccess(organization) && (
                  <ListGroup.Item as="li">
                    Search over 200 million Profiles instantly to find Prospects
                    by geography, interests, wealth, and more.
                  </ListGroup.Item>
                )}
                {!orgHasSideBrowserkickAccess(organization) && (
                  <ListGroup.Item as="li">
                    <a className="text-decoration-none" href="/settings">
                      Upgrade to Plus to see detailed profiles & license contact
                      details
                    </a>
                  </ListGroup.Item>
                )}
                {!orgHasBasicAccess(organization) && (
                  <ListGroup.Item as="li">
                    <a className="text-decoration-none" href="/settings">
                      Upgrade to Professional to search 200 million profiles
                    </a>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </GuideSection>
          </Col>

          <Col md={4} className="mb-3 text-center">
            <GuideSection title="Usage Overview">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Credits Used</th>
                    <th>Credits Left</th>
                    <th>Prospects Licensed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ToolTip title="Purchase Credits">
                        <Button
                          variant="link"
                          className="p-0 text-dark text-decoration-none"
                          href="/settings"
                        >
                          <Stars /> {creditsUsed.toLocaleString()}
                        </Button>
                      </ToolTip>
                    </td>
                    <td>
                      <ToolTip title="Purchase Credits">
                        <Button
                          variant="link"
                          className="p-0 text-dark text-decoration-none"
                          href="/settings"
                        >
                          <Stars /> {credits.toLocaleString()}
                        </Button>
                      </ToolTip>
                    </td>
                    <td>
                      <PeopleFill />{' '}
                      {(
                        organization?.prospects_purchased ?? []
                      ).length.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Navbar expand="lg">
                <Navbar.Toggle />
                <Navbar.Collapse>
                  <Nav className="mx-auto">
                    <Nav.Item>
                      <ButtonGroup>
                        <ToolTip title="Request a Custom List">
                          <Button
                            size="sm"
                            variant="link"
                            className="p-1 text-dark text-decoration-none"
                            onClick={() =>
                              confirm(
                                'Request Custom List',
                                'Request a custom list?',
                                () =>
                                  requestSendingSlackMessage(
                                    'facetedSearchRequestCustomList',
                                    'Request for a custom list.',
                                    'Request a Custom List',
                                    'Request for a custom list sent.',
                                  ),
                              )
                            }
                          >
                            <List /> Custom List
                          </Button>
                        </ToolTip>

                        <ToolTip title="Install Chrome Sidekick">
                          <Button
                            size="sm"
                            variant="link"
                            className="p-1 text-dark text-decoration-none"
                            target="_blank"
                            href="https://chromewebstore.google.com/detail/dre/hnkemefafbgmbmffecmlgpmjoifclcei"
                          >
                            <BrowserChrome /> Sidekick
                          </Button>
                        </ToolTip>

                        <ToolTip title="Request help or support">
                          <Button
                            size="sm"
                            variant="link"
                            className="p-1 text-dark text-decoration-none"
                            onClick={() =>
                              confirm(
                                'Request For Help',
                                <>
                                  Do you want to request help/support? Please
                                  review the following documentaion. It may
                                  resolve any questions you have.
                                  <Accordion
                                    className="mt-3"
                                    flush
                                    alwaysOpen={false}
                                  >
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header>
                                        How do I use the Professional Client
                                        Finder?
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <ListGroup
                                          as="ol"
                                          variant="flush"
                                          numbered
                                        >
                                          <ListGroup.Item as="li">
                                            Enters the facets you want to search
                                            with.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            Click <i>Count</i> to retrieve the
                                            total number of results.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            Click <i>Search</i> to retrieve the
                                            actual results.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            To search records already licensed,
                                            check <i>Search Licensed</i>.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            To see an overview of a record,
                                            click a marker on the map.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            To license a single record, after
                                            clicking the marker, click{' '}
                                            <i>License this Record</i>.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            To license all results, click{' '}
                                            <i>License All</i> from the top
                                            menu.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            To request a CSV of all currently
                                            licensed records, click{' '}
                                            <i>Licensed</i> from the top menu.
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1">
                                      <Accordion.Header>
                                        How do I use Sidekick Basic and Plus?
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <ListGroup
                                          as="ol"
                                          variant="flush"
                                          numbered
                                        >
                                          <ListGroup.Item as="li">
                                            Ensure you are using Chrome or Edge
                                            and the extension is installed.
                                            Click{' '}
                                            <a
                                              target="_blank"
                                              href="https://chromewebstore.google.com/detail/dre/hnkemefafbgmbmffecmlgpmjoifclcei"
                                              rel="noreferrer"
                                            >
                                              here
                                            </a>{' '}
                                            to install the extension.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            Be sure you are logged into DRE
                                            before browsing.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            Hover over any marked names to view
                                            a popup of predictions.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            Click the predictions popup to view
                                            other available matches.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            To license a record, click{' '}
                                            <i>License this Record</i>.
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li">
                                            To see more data on a licensed
                                            record, click "View Contact Info".
                                            You will be brought to this page.
                                            Where you will be able to search
                                            licensed records.
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </>,
                                () =>
                                  requestSendingSlackMessage(
                                    'facetedSearchRequestHelp',
                                    'Request for help/support.',
                                    'Get Help or Support',
                                    'Request for help sent.',
                                  ),
                                'Request Help',
                              )
                            }
                          >
                            <QuestionCircle /> Help
                          </Button>
                        </ToolTip>

                        <ToolTip title="Notice a bug or need an imporovement? Provide real-time feedback.">
                          <Button
                            size="sm"
                            variant="link"
                            className="p-1 text-dark text-decoration-none"
                            onClick={() => setShowFeedbackModal(true)}
                          >
                            <ChatDots /> Feedback
                          </Button>
                        </ToolTip>

                        <ToolTip title="View Terms of Use">
                          <Button
                            size="sm"
                            variant="link"
                            className="p-1 text-dark text-decoration-none"
                            href="https://www.dredata.ai/terms"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FileText /> Terms of Use
                          </Button>
                        </ToolTip>

                        <ToolTip title="View Privacy Policy">
                          <Button
                            size="sm"
                            variant="link"
                            className="p-1 text-dark text-decoration-none"
                            href="https://www.dredata.ai/privacy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Incognito /> Privacy Policy
                          </Button>
                        </ToolTip>
                      </ButtonGroup>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </GuideSection>
          </Col>
        </Row>

        <Card>
          <Card.Body>
            <Navbar expand="lg">
              <Navbar.Toggle />
              <Navbar.Collapse>
                <Nav className="me-auto">
                  <Nav.Item>
                    <ButtonGroup>
                      <ToolTip title="New Search">
                        <Button
                          disabled={!orgHasBasicAccess(organization)}
                          variant="light"
                          onClick={() => setShowNewModal(true)}
                        >
                          <Square />
                        </Button>
                      </ToolTip>
                      <ToolTip title="Open Search">
                        <Button
                          disabled={!orgHasBasicAccess(organization)}
                          variant="light"
                          onClick={() => setShowOpenModal(true)}
                        >
                          <ArrowUpSquare />
                        </Button>
                      </ToolTip>
                      <ToolTip title="Save Search">
                        <Button
                          disabled={!orgHasBasicAccess(organization)}
                          variant="light"
                          onClick={() => saveQueryOrSaveQueryAs()}
                        >
                          <ArrowDownSquare />
                        </Button>
                      </ToolTip>
                      <ToolTip title="Save Search As">
                        <Button
                          disabled={!orgHasBasicAccess(organization)}
                          variant="light"
                          onClick={() => saveQueryAs()}
                        >
                          <ArrowDownSquareFill />
                        </Button>
                      </ToolTip>
                      <ToolTip title="Edit Search Info">
                        <Button
                          disabled={
                            !orgHasBasicAccess(organization) ||
                            !facets?.current?._id
                          }
                          variant="light"
                          onClick={() => setShowFileDetailsModal(true)}
                        >
                          <InfoSquare />
                        </Button>
                      </ToolTip>
                    </ButtonGroup>
                  </Nav.Item>

                  <Nav.Item>
                    <ButtonGroup>
                      <ToolTip title="Undo">
                        <Button
                          disabled={
                            !orgHasBasicAccess(organization) || facets.isFirst
                          }
                          variant="light"
                          onClick={() => facets.goBack()}
                        >
                          <ArrowCounterclockwise />
                        </Button>
                      </ToolTip>

                      <ToolTip title="Redo">
                        <Button
                          disabled={
                            !orgHasBasicAccess(organization) || facets.isLast
                          }
                          variant="light"
                          onClick={() => facets.goForward()}
                        >
                          <ArrowClockwise />
                        </Button>
                      </ToolTip>
                    </ButtonGroup>
                  </Nav.Item>
                </Nav>

                <Nav>
                  <Nav.Item>
                    <ButtonGroup>
                      {!!results && (
                        <>
                          {!counted && searchPurchased && (
                            <ToolTip
                              title={showMap ? 'Display Table' : 'Display Map'}
                            >
                              <Button
                                variant="light"
                                onClick={() => setShowMap(!showMap)}
                              >
                                {!showMap && (
                                  <>
                                    <GeoAlt /> Map
                                  </>
                                )}
                                {showMap && (
                                  <>
                                    <List /> List
                                  </>
                                )}
                              </Button>
                            </ToolTip>
                          )}

                          {!counted &&
                            ((userLogged?.permissions ?? []) as any).includes(
                              'facetedSearchCanViewPolitical',
                            ) && (
                              <ToolTip
                                title={`${
                                  includePolitical ? 'Exclude' : 'Include'
                                } Poltical Columns`}
                              >
                                <Button
                                  variant="light"
                                  onClick={() => setShowPoliticalModal(true)}
                                >
                                  {includePolitical ? (
                                    <CheckSquare />
                                  ) : (
                                    <Square />
                                  )}{' '}
                                  Political
                                </Button>
                              </ToolTip>
                            )}

                          {!counted && !searchPurchased && (
                            <ToolTip title="Mark records as licensed">
                              <Button
                                variant="light"
                                onClick={() => {
                                  if (credits < results.length)
                                    setCreditsDelta(results.length - credits);
                                  else setShowMarkModal(true);
                                }}
                              >
                                {!!userLogged?.moadbCustomerId
                                  ? 'License All'
                                  : 'Mark As Licensed'}
                              </Button>
                            </ToolTip>
                          )}

                          {!counted &&
                            ((userLogged?.permissions ?? []) as any).includes(
                              'facetedSearchCanDownloadCSV',
                            ) && (
                              <ToolTip title="Generate CSV">
                                <Button
                                  variant="light"
                                  onClick={() => {
                                    if (includePolitical)
                                      setShowDownloadModal(true);
                                    else onGenerateCSV();
                                  }}
                                >
                                  Download CSV
                                </Button>
                              </ToolTip>
                            )}
                        </>
                      )}

                      {!searchPurchased &&
                        ((userLogged?.permissions ?? []) as any).includes(
                          'facetedSearchCanUploadAndMarkAsPurchased',
                        ) && (
                          <ToolTip title="Upload & Mark As Purchsed">
                            <Button
                              variant="light"
                              onClick={() =>
                                setShowUploadAndMarkAsPurchsedModal(true)
                              }
                            >
                              <Upload />
                              &nbsp; Upload & Mark As Licensed
                            </Button>
                          </ToolTip>
                        )}

                      {/*<ToolTip title="Switch to Query Search">
                    <Button
                      variant={!!results ? 'outline-light' : 'dark'}
                      onClick={() => setUseQuery(true)}
                    >
                      <ArrowLeftRight />
                    </Button>
                  </ToolTip>*/}
                    </ButtonGroup>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <Row>
              <Col
                md={2}
                className="border border-0 border-end position-relative"
              >
                <div className="sticky-top">
                  {((userLogged?.permissions ?? []) as any).includes(
                    'facetedSearchCanSelectCustomer',
                  ) && (
                    <InsetLabel label="Customer">
                      <MOADBDropdownCustomers
                        className="mb-3"
                        value={facets?.current?.Facets?.customerId}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              customerId: e.target.value,
                            },
                          })
                        }
                      />
                    </InsetLabel>
                  )}

                  <InsetLabel
                    label="Person's Name"
                    info="Enter a prospect's first name, last name, or both. Matched prospects will have the provided name."
                  >
                    <Form.Control
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.name}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            name: e.target.value,
                          },
                        })
                      }
                    />
                  </InsetLabel>

                  <InsetLabel
                    label="Region"
                    info="Select one or more geographical regions to filter states within the next field."
                  >
                    <MOADBDropdownRegions
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.region}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            region: e.target.value,
                          },
                        })
                      }
                    />
                  </InsetLabel>

                  <Row>
                    <Col
                      xs={facets?.current?.Facets?.state?.length === 1 ? 6 : 12}
                    >
                      <InsetLabel
                        label="State"
                        info="Select one or more states. Matched prospects will have a listed address within the provided state(s)."
                      >
                        <MOADBDropdownStates
                          disabled={!orgHasBasicAccess(organization)}
                          className="mb-3"
                          value={facets?.current?.Facets?.state}
                          region={facets?.current?.Facets?.region}
                          onChange={e =>
                            facets.update({
                              Facets: {
                                county: [],
                                state: e.target.value,
                              },
                            })
                          }
                        />
                      </InsetLabel>
                    </Col>

                    {facets?.current?.Facets?.state?.length === 1 && (
                      <Col xs={6}>
                        <InsetLabel
                          label="County"
                          info={`Select one or more ${facets?.current?.Facets?.state} counties. Matched prospects will have a listed address within one of the provided counties.`}
                        >
                          <MOADBDropdownCounties
                            disabled={!orgHasBasicAccess(organization)}
                            className="mb-3"
                            state={facets?.current?.Facets?.state}
                            value={facets?.current?.Facets?.county}
                            onChange={e =>
                              facets.update({
                                Facets: {
                                  county: e.target.value,
                                },
                              })
                            }
                          />
                        </InsetLabel>
                      </Col>
                    )}
                  </Row>

                  <AddressLookup
                    disabled={!orgHasBasicAccess(organization)}
                    className="mb-3"
                    value={facets?.current?.Facets?.address}
                    valueDistance={facets?.current?.Facets?.distance}
                    onChange={e => {
                      facets.update({
                        ...facets.current,
                        Facets: {
                          ...facets?.current?.Facets,
                          address: e.target.value.address,
                          distance: parseInt(e.target.value.distance, 10),
                          latLon: !!e.target.value?.latLon
                            ? {
                                lat: toFixed(e.target.value?.latLon?.lat, 3),
                                lon: toFixed(e.target.value?.latLon?.lon, 3),
                              }
                            : null,
                        },
                      });
                    }}
                  />

                  <InsetLabel
                    label="Interests"
                    info="Select one or more interests. Matched prospects will have, at least, one of selected interests."
                  >
                    <MultiSelect
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.interests}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            interests: e.target.value,
                          },
                        })
                      }
                      placeholder="Select Interests"
                    >
                      <option value="DONATES_TO_ANIMAL_WELFARE">
                        Animal Welfare{' '}
                      </option>
                      <option value="DONATES_TO_ARTS_AND_CULTURE">
                        Arts And Culture
                      </option>
                      <option value="DONATES_TO_CHILDRENS_CAUSES">
                        Childrens Causes
                      </option>
                      <option value="DONATES_TO_CONSERVATIVE_CAUSES">
                        Conservative Causes
                      </option>
                      <option value="DONATES_TO_HEALTHCARE">Healthcare</option>
                      <option value="DONATES_TO_INTERNATIONAL_AID_CAUSES">
                        International Aid Causes
                      </option>
                      <option value="DONATES_TO_LIBERAL_CAUSES">
                        Liberal Causes
                      </option>
                      <option value="DONATES_TO_LOCAL_COMMUNITY">
                        Local Community
                      </option>
                      <option value="DONATES_TO_POLITICAL_CAUSES">
                        Political Causes
                      </option>
                      <option value="DONATES_TO_VETERANS_CAUSES">
                        Veteran Causes
                      </option>
                      <option value="DONATES_TO_WILDLIFE_PRESERVATION">
                        Wildlife Preservation
                      </option>
                    </MultiSelect>
                  </InsetLabel>

                  <InsetLabel
                    label="Politics"
                    info="Select one or more political views. Matched prospects will hold one of the selected political views."
                  >
                    <MultiSelect
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.ideology}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            ideology: e.target.value,
                          },
                        })
                      }
                      placeholder="Select Politics"
                    >
                      <option value="1">Conservative</option>
                      <option value="2">Conservative Leaning</option>
                      <option value="3">Unaffiliated / Moderate</option>
                      <option value="4">Liberal Leaning</option>
                      <option value="5">Liberal</option>
                    </MultiSelect>
                  </InsetLabel>

                  <InsetLabel
                    label="Religion"
                    info="Select one or more religions. Matched prospects will hold one of the selected religions."
                  >
                    <MultiSelect
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.religion}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            religion: e.target.value,
                          },
                        })
                      }
                      placeholder="Select Religion"
                    >
                      <option value="Buddhist">Buddhist</option>
                      <option value="Catholic">Catholic</option>
                      <option value="Eastern Orthodox">Eastern Orthodox</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Jewish">Jewish</option>
                      <option value="Lutheran">Lutheran</option>
                      <option value="Mormon">Mormon</option>
                      <option value="Muslim">Muslim</option>
                      <option value="Oriental Orthodox">
                        Oriental Orthodox
                      </option>
                      <option value="Protestant">Protestant</option>
                      <option value="Shint">Shint</option>
                      <option value="Sikh">Sikh</option>
                      <option value="Unknown">Unknown</option>
                    </MultiSelect>
                  </InsetLabel>

                  <InsetLabel
                    label="Home Value"
                    info="Enter a minimum home value. Matched prospects will have an estimated home value equal to or greater than the value entered."
                  >
                    <InputSelect
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.minEstimatedHomeValue}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            minEstimatedHomeValue: e.target.value,
                          },
                        })
                      }
                      placeholder="Select Min Home Value"
                    >
                      <option value={0}>0</option>
                      <option value={100000}>100,000</option>
                      <option value={250000}>250,000</option>
                      <option value={500000}>500,000</option>
                      <option value={1000000}>1,000,000</option>
                    </InputSelect>
                  </InsetLabel>

                  <InsetLabel
                    label="Household Income"
                    info="Enter a minimum household income. Matched prospects will have an estimated household income equal to or greater than the value entered."
                  >
                    <InputSelect
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.minEstimatedIncome}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            minEstimatedIncome: e.target.value,
                          },
                        })
                      }
                      placeholder="Select Min Household Income"
                    >
                      <option value={0}>0</option>
                      <option value={50000}>50,000</option>
                      <option value={100000}>100,000</option>
                      <option value={250000}>250,000</option>
                      <option value={500000}>500,000</option>
                      <option value={1000000}>1,000,000</option>
                    </InputSelect>
                  </InsetLabel>

                  <InsetLabel
                    label="Donation Capacity"
                    info="Enter a minimum donation capacity. Matched prospects will have an estimated donation capacity equal to or greater than the value entered."
                  >
                    <InputSelect
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.minDonationCapacity}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            minDonationCapacity: e.target.value,
                          },
                        })
                      }
                      placeholder="Select Min Donation Capacity"
                    >
                      <option value={0}>0</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={1000}>1000</option>
                      <option value={5000}>5000</option>
                      <option value={10000}>10000</option>
                    </InputSelect>
                  </InsetLabel>

                  {((userLogged?.permissions ?? []) as any).includes(
                    'facetedSearchCanFilterEmailPhone4YrGrad',
                  ) && (
                    <ButtonGroup className="mb-3 w-100" size="sm">
                      <Toggle
                        disabled={!orgHasBasicAccess(organization)}
                        singleButton
                        labelOff={
                          <>
                            <Square /> Email
                          </>
                        }
                        labelOn={
                          <>
                            <CheckSquare /> Email
                          </>
                        }
                        value={facets?.current?.Facets?.hasEmail}
                        onChange={value =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              hasEmail: value,
                            },
                          })
                        }
                      />

                      <Toggle
                        disabled={!orgHasBasicAccess(organization)}
                        singleButton
                        labelOff={
                          <>
                            <Square /> Phone
                          </>
                        }
                        labelOn={
                          <>
                            <CheckSquare /> Phone
                          </>
                        }
                        value={facets?.current?.Facets?.hasPhone}
                        onChange={value =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              hasPhone: value,
                            },
                          })
                        }
                      />

                      <Toggle
                        disabled={!orgHasBasicAccess(organization)}
                        singleButton
                        labelOff={
                          <>
                            <Square /> 4 Yr Grad
                          </>
                        }
                        labelOn={
                          <>
                            <CheckSquare /> 4 Yr Grad
                          </>
                        }
                        value={facets?.current?.Facets?.is4YrGrad}
                        onChange={value =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              is4YrGrad: value,
                            },
                          })
                        }
                      />
                    </ButtonGroup>
                  )}

                  {/*<InsetLabel label="Sort">
                    <MOADBDropdownColumns
                      className="mb-3"
                      value={facets?.current?.Facets?.sort}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            sort: e.target.value,
                          },
                        })
                      }
                    ></MOADBDropdownColumns>
                  </InsetLabel>

                  <InsetLabel label="Order">
                    <Select
                      className="mb-3"
                      value={facets?.current?.Facets?.order}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            order: e.target.value,
                          },
                        })
                      }
                    >
                      <option value="asc">Asc</option>
                      <option value="desc">Desc</option>
                    </Select>
                  </InsetLabel>*/}

                  <InsetLabel
                    label="Limit"
                    info="Enter the maximum records returned. Value must be less than or equal to 5,000."
                  >
                    <Form.Control
                      disabled={!orgHasBasicAccess(organization)}
                      type="number"
                      max={1000}
                      value={facets?.current?.Facets?.limit}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            limit: Math.min(5000, parseInt(e.target.value, 10)),
                          },
                        })
                      }
                    />
                  </InsetLabel>

                  {!!facetError && (
                    <Alert variant="danger" className="mt-3">
                      {facetError}
                    </Alert>
                  )}

                  {(credits > 0 && orgHasBasicAccess(organization)) ||
                  organization.canBypassPaywall ||
                  searchPurchased ? (
                    <ButtonGroup className="mt-3 w-100" size="sm">
                      {/*<ToolTip title="Create Data Profile">
                  <Button
                    variant={!!results ? 'outline-light' : 'dark'}
                    onClick={() => setShowDataProfileModal(true)}
                  >
                    <PersonLinesFill /> Data Profile
                  </Button>
                </ToolTip>*/}

                      <ToolTip title="Return Total">
                        <Button
                          disabled={!orgHasBasicAccess(organization)}
                          variant="outline-primary"
                          onClick={() => onSearch(false, false, null, true)}
                        >
                          Count
                        </Button>
                      </ToolTip>

                      <ToolTip
                        title={
                          searchPurchased
                            ? 'Search Licensed Records (0 Credits)'
                            : 'Search (1 Credit)'
                        }
                      >
                        <Button
                          disabled={!orgHasBasicAccess(organization)}
                          onClick={() => onSearch()}
                        >
                          Search
                        </Button>
                      </ToolTip>
                    </ButtonGroup>
                  ) : (
                    <ToolTip
                      title={
                        !orgHasBasicAccess(organization)
                          ? 'Click to Upgrade'
                          : 'Click to Purchase More Credits'
                      }
                    >
                      <Button className="mt-3 w-100" size="sm" href="/settings">
                        {!orgHasBasicAccess(organization)
                          ? 'Upgrade to Continue'
                          : 'Purchase Credits to Continue'}
                      </Button>
                    </ToolTip>
                  )}

                  <ToolTip title="Licensed">
                    <Button
                      disabled={!orgHasBasicAccess(organization)}
                      className="ps-0 text-decoration-none"
                      size="sm"
                      variant="link"
                      onClick={() => updateSearchPurchased(!searchPurchased)}
                    >
                      {searchPurchased ? <CheckSquare /> : <Square />} &nbsp;
                      Search Licensed
                    </Button>
                  </ToolTip>
                </div>
              </Col>

              <Col
                md={8}
                className="p-0 my-3 my-md-0"
                style={{ minHeight: '75vh' }}
              >
                {!!markedAsPurchased && (
                  <Alert variant="info">
                    Prior results have been marked as licensed. Please execute
                    another faceted search to explore what records are still
                    available.
                  </Alert>
                )}

                {!!error && <Alert variant="danger">{error}</Alert>}

                {!results && (
                  <div
                    className="fs-3 h-100 w-100 px-2 py-2"
                    style={{
                      backgroundImage: `url(${
                        !!orgHasBasicAccess(organization)
                          ? PreviewLive
                          : Preview
                      })`,
                      backgroundSize: 'cover',
                    }}
                  >
                    {!!orgHasBasicAccess(organization) && (
                      <Card className="mx-auto text-center w-50">
                        <Card.Body>
                          <div className="fw-bold">Prospect Search</div>
                          Search over 200 million Profiles by name, geography,
                          wealth or psychographics
                        </Card.Body>
                      </Card>
                    )}

                    {!orgHasBasicAccess(organization) && (
                      <div className="text-center">
                        <Button className="fs-3 w-50" href="/settings">
                          Unlock Search Feature
                          <br />
                          (Professional Plan Required)
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                {!!results && !showMap && (
                  <EventBasedTable
                    data={results}
                    maxRows={100}
                    onColumnFilter={onColumnFilter}
                    onColumnHeader={onColumnHeader}
                    onRowColumn={onRowColumn}
                  />
                )}

                {!!results && showMap && (
                  <MOADBMap
                    credits={credits}
                    key={JSON.stringify(results)}
                    data={results}
                    onColumnFilter={onColumnFilter}
                    onColumnHeader={onColumnHeader}
                    searchPurchased={searchPurchased}
                    setCreditsDelta={setCreditsDelta}
                    setMarkSingleProspect={setMarkSingleProspect}
                    showContactInfo={showContactInfo}
                  />
                )}
              </Col>

              <Col
                md={2}
                className="border border-0 border-end position-relative"
              >
                <div className="sticky-top">
                  <GuideSection title="My Prospects">
                    <div className="mb-3 text-center">
                      <small>
                        See all the details on the Prospects you've licensed
                      </small>
                    </div>

                    <ToolTip title="Display your already licensed prospects within a map">
                      <Button
                        size="sm"
                        variant="dark"
                        className="p-1 mb-3 w-100"
                        onClick={() => onSearchMyProspects(true)}
                      >
                        Map Prospects
                      </Button>
                    </ToolTip>

                    <ToolTip title="Display your already licensed prospects within a table">
                      <Button
                        size="sm"
                        variant="dark"
                        className="p-1 mb-3 w-100"
                        onClick={() => onSearchMyProspects(false)}
                      >
                        Table View
                      </Button>
                    </ToolTip>

                    <ToolTip title="Request your licensed records.">
                      <Button
                        size="sm"
                        variant="dark"
                        className="p-1 mb-3 w-100"
                        onClick={() =>
                          confirm(
                            'Request For Licensed Records',
                            'Request export of all licensed records?',
                            () =>
                              requestSendingSlackMessage(
                                'facetedSearchRequestLicensedRecords',
                                'Request for licensed records.',
                                'Request Licensed Records',
                                'Request for a licensed records sent.',
                              ),
                          )
                        }
                      >
                        Request Export
                      </Button>
                    </ToolTip>

                    <Button
                      size="sm"
                      variant="primary"
                      className="p-1 w-100"
                      href="/settings"
                    >
                      {!orgHasBasicAccess(organization) ? (
                        <>Unlock My Prospects</>
                      ) : (
                        <>Purchase Additional Credits</>
                      )}
                    </Button>
                  </GuideSection>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <QNA />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
