import * as React from 'react';
import { Button } from 'react-bootstrap';

const Footer = () => (
  <div
    className="fs-5 text-center"
    style={{
      height: '75px',
      width: '100vw',
    }}
  >
    <div>Copyright © DRE Data Service LLC {new Date().getFullYear()}</div>
    <a
      className="fs-5 ms-3 p-0 text-dark"
      href="https://www.dredata.ai/terms"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Use
    </a>
    <a
      className="fs-5 ms-3 p-0 text-dark"
      href="https://www.dredata.ai/privacy"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  </div>
);

export default Footer;
